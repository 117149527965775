
$(window).on('load', function() {
    const sliderInfo = {
    awarddress: {
      path: 'awarddress',
      // quantity: 19
      quantity: 18
    },
    bridedress: {
      path: 'bridedress',
      // quantity: 18
      quantity: 20
    },
    coctail: {
      path: 'coctail',
      quantity: 14
    },
    accessories: {
      path: 'accessories',
      quantity: 12
    }
  };

  const sliderName = $('.fullscreen-slider__slider').attr('data-slider');
  const path = sliderInfo[sliderName].path;
  $('.fullscreen-slider__slider').empty();
  for(let i = 1, max = sliderInfo[sliderName].quantity; i <= max; i++) {

    const template = `<div class="fullscreen-slider__item">
                        <div class="fullscreen-slider__item-wrapper">
                            <img src="/img/${path}/big-slider/${i}.jpg" alt="${sliderName}" class="fullscreen-slider__img"/>
                        </div>
                      </div>`;
    $('.fullscreen-slider__slider').append(template);
  }
  setTimeout(() => {
    $('.fullscreen-slider__slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 800,
      arrows: true,
      nextArrow: `<span class="fullscreen-slider__next-arrow"></span>`,
      prevArrow: `<span class="fullscreen-slider__prev-arrow"></span>`,
      responsive: [{
        breakpoint: 450,
        settings: {
          arrows: false
        }
      }, ]
    });
  }, 1);
  function activateSlider () {
    $('body').addClass('prevent-scroll');
    $('.fullscreen-slider').css('display', 'block');
    // $('.fullscreen-slider__slider')[0].slick.refresh();
    $('.fullscreen-slider__slider').slick('refresh');
  }

  $('.js-gallery').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    var slider = $(this).data('slider');
    $('.fullscreen-slider__slider').attr('data-slider', slider);
    const sliderInfo = {
    awarddress: {
      path: 'awarddress',
      // quantity: 19
      quantity: 18
    },
    bridedress: {
      path: 'bridedress',
      // quantity: 18
      quantity: 20
    },
    coctail: {
      path: 'coctail',
      quantity: 14
    },
    accessories: {
      path: 'accessories',
      quantity: 12
    }
  };

  const sliderName = $('.fullscreen-slider__slider').attr('data-slider');
  const path = sliderInfo[sliderName].path;
  // $('.fullscreen-slider__slider').empty();
  $('.fullscreen-slider__slider').slick('slickRemove', null, null, true);
  for(let i = 1, max = sliderInfo[sliderName].quantity; i <= max; i++) {

    const template = `<div class="fullscreen-slider__item"><div class="fullscreen-slider__item-wrapper">1<img src="/img/${path}/big-slider/${i}.jpg" alt="${sliderName}" class="fullscreen-slider__img"/></div></div>`;
    // $('.fullscreen-slider__slider').append(template);
    $(".fullscreen-slider__slider").slick('slickAdd', template);
  }
    setTimeout(function(){
      activateSlider();
      // $('.fullscreen-slider__slider').slick('reinit');
    },100);
  });

  $('.wantadress__picture,.dresses__container').on('click', function() {
      activateSlider();
  });

  $('#close-fs-slider').on('click', function() {
      $('body').removeClass('prevent-scroll');
      $('.fullscreen-slider').fadeOut(200);
  });

});
